.video-gallery-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  .close-icon-container {
    width: 52px;
    height: 52px;
    background: white;
    cursor: pointer;
    .close-icons {
      color: black;
    }
  }
}

.image-container-gallery {
  width: 60%;
  height: 60%;
  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.description-container-gallery {
  width: 60%;
  color: white;
  padding-top: 64px;
  p {
    font-weight: normal;
    font-size: 13px;
  }
  h2 {
    font-weight: bold;
    font-size: 24px;
  }
}

.description-container-video-gallery {
  width: 50%;
  color: white;
  padding-top: 64px;
}
