.image-full {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.video-full {
  width: 80%;
  height: 80%;
  object-fit: cover;
  &-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-with-background {
      background-color: rgba(0, 0, 0, 0.85);
    }
  }
}

.gallery-carousel-controls {
  position: absolute;
  top: 20px;
  background: #1d1c1b;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 30px;
  color: #ffffff;
  padding: 10px;
  z-index: 2;
  display: flex;
  align-items: center;
  & .left-arrow {
    transform: rotate(-90deg);
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
  }
  & .right-arrow {
    transform: rotate(90deg);
    width: 20px;
    height: 20px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.icon-type {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  &-md {
    width: 15px;
    height: 15px;
    margin: 0 5px;
  }
}

.ellipsis-icon {
  width: 6px;
  height: 6px;
  margin: 0 5px;
  cursor: pointer;
}

.placeholder {
  width: 20px;
  height: 20px;
  &-left {
    margin-right: 10px;
  }
  &-right {
    margin-left: 10px;
  }
}
