.minified-menu {
  width: 200px;
  height: 50px;
  position: absolute;
  left: -75px;
  transform: rotate(-90deg);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;

  &-title {
    width: 150px;
    height: 50px;
    background: #000000;
    border-radius: 0px 0px 2px 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    & h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
    }
  }

  &-logo {
    width: 50px;
    height: 50px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      transform: rotate(90deg);
      max-width: 100%;
    }
  }
}

.menu {
  width: 300px;
  height: 100%;
  position: absolute;
  z-index: 2;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background: #000000;
  color: #ffffff;

  &-header {
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    color: #000000;
    padding: 12px;

    & img {
      max-height: 100%;
    }

    & .close-icon {
      cursor: pointer;
      color: black;
    }
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 16px 21px;
    width: 100%;
    cursor: pointer;
    background-color: black;
    margin: 2px 0;

    & .dropdown-icon {
      width: 24px;
      height: 24px;

      &-inverted {
        transform: rotate(180deg);
      }
    }
  }
}

.submenu-container {
  max-height: 450px;
  overflow: auto;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #6c6c6c;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #3ECFAF;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(249, 179, 66, 0.8);
  }
}

.show-submenu {
  animation-name: topFadeIn;
  animation-duration: 0.5s;
}

.hide-submenu {
  animation-name: topFadeOut;
  animation-duration: 0.5s;
}

.show-submenu-2 {
  animation-name: topFadeIn2;
  animation-duration: 0.5s;
}

.hide-submenu-2 {
  animation-name: topFadeOut2;
  animation-duration: 0.5s;
}

.show-submenu-3 {
  animation-name: topFadeIn3;
  animation-duration: 0.5s;
}

.hide-submenu-3 {
  animation-name: topFadeOut3;
  animation-duration: 0.5s;
}

.floorplan {
  width: 100%;
  position: relative;
  height: 150px;

  &-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer;

    &-active {
      background-color: rgba(0, 0, 0, 0.5);

      &-indicator {
        height: 100%;
        width: 6px;
        background-color: #3ECFAF;
        position: absolute;
        left: 5px;
        top: 0;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }

    &-name {
      font-weight: 900;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 16px;
    }

    &-features {
      display: flex;
      align-items: center;

      &-icon {
        width: 15px;
        height: 15px;
      }

      &-value {
        margin: 0 10px;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  &-thumbnail {
    max-height: 100%;
  }
}

.amenity {
  width: 100%;
  position: relative;
  height: 150px;

  &-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer;

    &-active {
      background-color: rgba(0, 0, 0, 0.5);

      &-indicator {
        height: 100%;
        width: 6px;
        background-color: #3ECFAF;
        position: absolute;
        left: 5px;
        top: 0;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }

    &-name {
      font-weight: 900;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 16px;
    }

    &-icons {
      width: 24px;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &-icon-type {
      width: 24px;
      height: 24px;
      margin-bottom: 15px;
    }
  }

  &-thumbnail {
    max-height: 100%;
  }
}

.minified-left-fade-out {
  animation-name: minifiedLeftFadeOut;
  animation-duration: 0.5s;
}

.minified-left-fade-in {
  animation-name: minifiedLeftFadeIn;
  animation-duration: 0.5s;
}

.left-fade-in {
  animation-name: leftFadeIn;
  animation-duration: 0.5s;
}

.left-fade-out {
  animation-name: leftFadeOut;
  animation-duration: 0.5s;
}

.hidden {
  display: none;
}

@keyframes minifiedLeftFadeOut {
  0% {
    left: -75px;
  }

  100% {
    left: -125px;
  }
}

@keyframes minifiedLeftFadeIn {
  0% {
    left: -125px;
  }

  100% {
    left: -75px;
  }
}

@keyframes leftFadeIn {
  0% {
    left: -300px;
  }

  100% {
    left: 0;
  }
}

@keyframes leftFadeOut {
  0% {
    left: 0;
  }

  100% {
    left: -300px;
  }
}

@keyframes topFadeIn {
  0% {
    height: 0;
  }

  100% {
    height: 150px;
  }
}

@keyframes topFadeOut {
  0% {
    height: 150px;
  }

  100% {
    height: 0;
  }
}

@keyframes topFadeIn2 {
  0% {
    height: 0;
  }

  100% {
    height: 300px;
  }
}

@keyframes topFadeOut2 {
  0% {
    height: 300px;
  }

  100% {
    height: 0;
  }
}

@keyframes topFadeIn3 {
  0% {
    height: 0;
  }

  100% {
    height: 600px;
  }
}

@keyframes topFadeOut3 {
  0% {
    height: 600px;
  }

  100% {
    height: 0;
  }
}

.athum-logo {
  position: absolute;
  left: 30px;
  bottom: 30px;
  width: 32px;
  height: 32px;
  animation-name: logoFadeIn;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes logoFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.delay-menu {
  animation-delay: 1.5s;
  left: -125px;
  animation-fill-mode: forwards;
}

.details-menu {
  height: 53px;
  display: flex;
  align-items: center;
  color: #C4C4C4;

  &-item {
    padding: 16px 36px;
    cursor: pointer;
    border-right: 1px solid #474747;

    &:last-child {
      border-left: 1px solid #474747;
      border-right: none;
    }

    &.active {
      border-bottom: 3px solid #3ECFAF;
    }
  }


}