.twod-container {
  width: 100%;
  height: 100%;
  position: relative;
  background: black;
  & img {
    // object-fit: cover;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  & .hotspot {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: all 0.2s;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-family: sans-serif;
    color: #555;
    z-index: 100;
    background-size: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    &.image {
      background: url(../../assets/Icons/Image_hotspot.svg);
      background-size: 100%;
    }
    &.video {
      background: url(../../assets/Icons/Video_hotspot.svg);
      background-size: 100%;
    }
    &.infospots {
      background: url(../../assets/Icons/Info_Hotspot.svg);
      background-size: 100%;
    }
    &.hotspots {
      background: url(../../assets/Icons/Hotspot_hotspot.svg);
      background-size: 100%;
    }
  }
}
.tooltip-image {
  position: absolute;
  width: 300px;
  height: 200px;
  background-color: #4a4a4a;
  color: white;
  opacity: 0;
  transform: translate(-34%, -28%);
  transition: opacity 0.3s, transform 0.3s;
  padding: 8px;
  & * {
    pointer-events: none;
  }
  &.active {
    opacity: 1;
    transform: translate(-34%, -28%);
    z-index: 101;
    cursor: pointer;
    border-radius: 8px;
    &.no-thumbnail {
      opacity: 0.75;
      cursor: auto;
    }
  }
}

.body-container {
  width: 60%;
  height: 70%;
  margin: auto;
  outline: none;
  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@keyframes fader {
  50% {
    border-color: rgba(255, 255, 255, 0.2);
  }
  100% {
    border-color: rgba(255, 255, 255, 0.8);
  }
}
