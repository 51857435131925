.menu-action {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  border: 1px solid;
  border-color: #ffffff;
  position: absolute;
  color: #ffffff;
  cursor: pointer;
  &-active {
    color: #ed6b6a;
    border-color: #ed6b6a;
  }
  &-no-border {
    border: none;
  }
  &:hover:not([disabled]) {
    color: #ed6b6a;
    border-color: #ed6b6a;
  }
  &[disabled] {
    background: rgba(29, 28, 27, 0.5);
    border-color: rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
    cursor: auto;
  }
  &-submenu {
    display: flex;
    right: 36px;
    height: 36px;
    position: absolute;
    justify-content: flex-end;
    &-option {
      height: 36px;
      min-width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 36px;
      border: 1px solid;
      border-color: #ffffff;
      color: #ffffff;
      cursor: pointer;
      padding: 0 10px;
      margin: 0 10px;
      background-color: #1d1c1b;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      &:hover:not([disabled]) {
        background-color: #ed6b6a;
      }
      &-active {
        background-color: #ed6b6a;
      }
    }
  }
}

.secondary-action {
  background: #1d1c1b;
}

.info-action {
  bottom: 30px;
  right: 30px;
  animation-name: infoActionFadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: 1s;
}

.info-icon {
  height: 16px;
  width: 16px;
}

@keyframes infoActionFadeIn {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.slow-mo-action {
  bottom: 30px;
  right: 100px;
  animation-name: slowMoActionFadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: 1s;
}

@keyframes slowMoActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden;
  }
  100% {
    bottom: 30px;
    right: 100px;
    visibility: visible;
  }
}

.slow-mo-icon {
  height: 24px;
  width: 24px;
}

.language-action {
  bottom: 30px;
  right: 170px;
  animation-name: languageActionFadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: 1s;
}

@keyframes languageActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden;
  }
  100% {
    bottom: 30px;
    right: 170px;
    visibility: visible;
  }
}

.share-action {
  bottom: 30px;
  right: 240px;
  animation-name: shareActionFadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: 1s;
}

@keyframes shareActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden;
  }
  100% {
    bottom: 30px;
    right: 240px;
    visibility: visible;
  }
}

.share-icon {
  height: 24px;
  width: 24px;
}

.full-screen-action {
  bottom: 30px;
  right: 310px;
  animation-name: fullScreenActionFadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: 1s;
}

@keyframes fullScreenActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden;
  }
  100% {
    bottom: 30px;
    right: 310px;
    visibility: visible;
  }
}

.full-screen-icon {
  height: 24px;
  width: 24px;
}

.light-action {
  bottom: 100px;
  right: 30px;
  animation-name: lightActionFadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: 1s;
}

.mute-action {
  bottom: 100px;
  right: 30px;
}

.mute-icon {
  height: 20px;
  width: 20px;
}

@keyframes lightActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden;
  }
  100% {
    bottom: 100px;
    right: 30px;
    visibility: visible;
  }
}

.light-icon {
  height: 20px;
  width: 20px;
}

.settings-action {
  bottom: 170px;
  right: 30px;
  animation-name: settingsActionFadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: 1s;
}

@keyframes settingsActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden;
  }
  100% {
    bottom: 170px;
    right: 30px;
    visibility: visible;
  }
}

.settings-icon {
  height: 20px;
  width: 20px;
}

.styles-action {
  bottom: 240px;
  right: 30px;
  animation-name: stylesActionFadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: 1s;
}

@keyframes stylesActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden;
  }
  100% {
    bottom: 240px;
    right: 30px;
    visibility: visible;
  }
}

.styles-new-icon {
  height: 20px;
  width: 20px;
}

.eye-action {
  bottom: 310px;
  right: 30px;
  animation-name: eyeActionFadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: 1s;
}

@keyframes eyeActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden;
  }
  100% {
    bottom: 310px;
    right: 30px;
    visibility: visible;
  }
}

.eye-icon {
  height: 20px;
  width: 20px;
}

.copied {
  position: absolute;
  bottom: 75px;
  right: 226px;
  background-color: #ed6b6a;
  color: #ffffff;
  padding: 5px;
  border-radius: 4px;
}
