.schedule-button {
  height: 35px;
  width: 150px !important;
  position: absolute;
  top: 20px;
  right: 16px;
  z-index: 1;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  & h6 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin: 0px;
  }
}

.schedule-steps-container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  z-index: 3;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  & .header-container {
    height: 54px;
    background: #ffffff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 18px;
    & .close-icons {
      cursor: pointer;
    }
    & .logo {
      height: 100%;
      & img {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.step-one-container {
  display: flex;
  width: 393px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: #ffffff;
  > * {
    margin-bottom: 24px;
  }
  & .buttons-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    & button {
      font-size: 10px;
      border-radius: 20px;
    }
    @media screen and (max-width: 481px) {
      flex-direction: column;
      & button {
        margin-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 481px) {
    width: 100%;
    padding: 10px;
  }
}

.content-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 481px) {
    align-items: flex-start;
  }
}

.step-two-container {
  width: 400px;
  background: #f3f3f5;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 52px 25px;
  @media screen and (max-width: 481px) {
    width: 100%;
    border-radius: 0px;
    padding: 0px;
    & p {
      padding: 0px 25px;
      margin-bottom: 0;
    }
  }
}

.step-two-second-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 0px;
  color: #1d1c1b;
  & .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    & .header-current-date {
      margin-left: 95px;
    }
    & h4 {
      margin: 0px;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
    }
    & h6 {
      font-size: 16px;
      font-weight: normal;
    }
  }

  & .list-container {
    overflow: auto;
    width: 100%;
    height: 270px;
    margin-top: 10px;
    padding: 10px;
    & .item {
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 40px;
      width: 100%;
      margin-bottom: 15px;
      padding: 12px 0px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.step-three-second-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #1d1c1b;
  & .header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    & .header-current-date {
      margin-left: 35px;
    }
    & h4 {
      margin: 0px;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
    }
    & h6 {
      font-size: 16px;
      font-weight: normal;
    }
  }

  & .form-container {
    width: 100%;
    margin-top: 10px;
    & .first-line {
      display: flex;
      justify-content: space-between;
    }
    & .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 31px;
      & .button {
        background: #000000;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 20px;
      }
      @media screen and (max-width: 481px) {
        margin-top: 5px;
      }
    }
    > * {
      margin-bottom: 15px;
    }

    @media screen and (max-width: 481px) {
      padding: 0px 5px;
    }
  }

  & .subheader-title {
    width: 100%;
  }
}

.step-confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 16px;
  & h3 {
    font-size: 16px;
    font-weight: bold;
    padding: 0px 30px;
  }
  & p {
    padding: 12px 30px;
  }
  & .confirmation-title {
    padding: 35px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    font-size: 30px;
  }
  & .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & button {
      background: #000000;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 20px;
    }
  }
}

.separator {
  background-color: #cdcdcd;
  width: 100%;
  &.top-margin {
    margin: 18px 0px;
  }
}

.MuiOutlinedInput-root {
  border-radius: 10px !important;
}

.MuiCalendarPicker-root {
  background: #f3f3f5;
}
