@import url(https://fonts.googleapis.com/css?family=Roboto);
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-family: serif;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.77);
  font-family: 'Roboto', sans-serif;
  background: #fafafa; }
  body.modal-active,
  html.modal-active {
    overflow: hidden; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

#root {
  width: 100%;
  position: fixed; }

#root .blur {
  filter: blur(25px);
  -webkit-filter: blur(25px); }

.blur {
  filter: blur(25px);
  -webkit-filter: blur(25px); }

.display-none {
  display: none !important;
  width: 0px;
  transition: all 0.4s ease-in-out; }

.filter-menu .MuiPaper-root {
  background-color: #1D1C1B; }

.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  opacity: 1;
  transition: opacity 1s, z-index 1s; }
  .loader.white-background {
    background-color: white; }
  .loader.scene {
    background-color: transparent; }
  .loader.loader.none {
    opacity: 0;
    z-index: -1; }
    .loader.loader.none .loader-image-container {
      opacity: 0; }
  .loader.show {
    opacity: 1; }
  .loader .loader-image-container {
    background-color: white;
    height: 100px;
    width: 100px;
    border-radius: 50%; }
  .loader.display-none {
    display: 'none'; }

.three-sixty-blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: 1s opacity; }
  .three-sixty-blur.none {
    opacity: 0; }

.icon {
  position: fixed;
  min-width: 120px;
  min-height: 48px;
  width: 152px;
  height: 60px;
  top: 16px;
  left: 16px;
  object-fit: contain;
  z-index: 1000;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
  /* 
  &:hover {
    cursor: pointer;
  } */ }
  .icon img {
    object-fit: contain;
    width: 100%;
    height: 100%; }
  .icon.hide {
    opacity: 0; }

@media (max-width: 767px) {
  .icon {
    top: auto;
    bottom: 3%;
    right: 0;
    left: 0;
    margin: 0 auto;
    -webkit-justify-content: center;
            justify-content: center;
    /*  &:hover {
      cursor: pointer;
    } */ }
    .icon img {
      object-fit: contain;
      width: 100%;
      height: 100%; }
    .icon.hide {
      opacity: 0; } }

.indicator-container {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 27px;
  border-radius: 24px;
  text-transform: capitalize;
  z-index: 1000;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: all 0.3s ease-in-out;
  padding: 0px 5px;
  -webkit-justify-content: center;
          justify-content: center; }
  .indicator-container .logo {
    display: none; }
  .indicator-container .layout-decoration-container {
    text-align: center; }
    .indicator-container .layout-decoration-container .layout-name {
      height: 12px;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #ffffff; }
    .indicator-container .layout-decoration-container .decoration-style {
      padding-left: 5px;
      height: 12px;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff; }
  .indicator-container.hide .indicator-container {
    opacity: 0; }

@media (min-width: 768px) {
  .indicator-container {
    top: auto;
    bottom: 20px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .indicator-container .logo {
      display: block;
      width: 19.69px;
      height: 19.69px;
      margin-right: 8px; }
    .indicator-container .layout-decoration-container .layout-name {
      font-size: 16px;
      line-height: 0.75; }
    .indicator-container .layout-decoration-container .decoration-style {
      font-size: 16px;
      font-weight: 300;
      line-height: 0.75; } }

.menu-action {
  width: 36px;
  height: 36px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 36px;
  border: 1px solid;
  border-color: #ffffff;
  position: absolute;
  color: #ffffff;
  cursor: pointer; }
  .menu-action-active {
    color: #ed6b6a;
    border-color: #ed6b6a; }
  .menu-action-no-border {
    border: none; }
  .menu-action:hover:not([disabled]) {
    color: #ed6b6a;
    border-color: #ed6b6a; }
  .menu-action[disabled] {
    background: rgba(29, 28, 27, 0.5);
    border-color: rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.5);
    cursor: auto; }
  .menu-action-submenu {
    display: -webkit-flex;
    display: flex;
    right: 36px;
    height: 36px;
    position: absolute;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .menu-action-submenu-option {
      height: 36px;
      min-width: 150px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      border-radius: 36px;
      border: 1px solid;
      border-color: #ffffff;
      color: #ffffff;
      cursor: pointer;
      padding: 0 10px;
      margin: 0 10px;
      background-color: #1d1c1b;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px; }
      .menu-action-submenu-option:hover:not([disabled]) {
        background-color: #ed6b6a; }
      .menu-action-submenu-option-active {
        background-color: #ed6b6a; }

.secondary-action {
  background: #1d1c1b; }

.info-action {
  bottom: 30px;
  right: 30px;
  -webkit-animation-name: infoActionFadeIn;
          animation-name: infoActionFadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  visibility: hidden;
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

.info-icon {
  height: 16px;
  width: 16px; }

@-webkit-keyframes infoActionFadeIn {
  0% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

@keyframes infoActionFadeIn {
  0% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

.slow-mo-action {
  bottom: 30px;
  right: 100px;
  -webkit-animation-name: slowMoActionFadeIn;
          animation-name: slowMoActionFadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  visibility: hidden;
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

@-webkit-keyframes slowMoActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 30px;
    right: 100px;
    visibility: visible; } }

@keyframes slowMoActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 30px;
    right: 100px;
    visibility: visible; } }

.slow-mo-icon {
  height: 24px;
  width: 24px; }

.language-action {
  bottom: 30px;
  right: 170px;
  -webkit-animation-name: languageActionFadeIn;
          animation-name: languageActionFadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  visibility: hidden;
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

@-webkit-keyframes languageActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 30px;
    right: 170px;
    visibility: visible; } }

@keyframes languageActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 30px;
    right: 170px;
    visibility: visible; } }

.share-action {
  bottom: 30px;
  right: 240px;
  -webkit-animation-name: shareActionFadeIn;
          animation-name: shareActionFadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  visibility: hidden;
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

@-webkit-keyframes shareActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 30px;
    right: 240px;
    visibility: visible; } }

@keyframes shareActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 30px;
    right: 240px;
    visibility: visible; } }

.share-icon {
  height: 24px;
  width: 24px; }

.full-screen-action {
  bottom: 30px;
  right: 310px;
  -webkit-animation-name: fullScreenActionFadeIn;
          animation-name: fullScreenActionFadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  visibility: hidden;
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

@-webkit-keyframes fullScreenActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 30px;
    right: 310px;
    visibility: visible; } }

@keyframes fullScreenActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 30px;
    right: 310px;
    visibility: visible; } }

.full-screen-icon {
  height: 24px;
  width: 24px; }

.light-action {
  bottom: 100px;
  right: 30px;
  -webkit-animation-name: lightActionFadeIn;
          animation-name: lightActionFadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  visibility: hidden;
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

.mute-action {
  bottom: 100px;
  right: 30px; }

.mute-icon {
  height: 20px;
  width: 20px; }

@-webkit-keyframes lightActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 100px;
    right: 30px;
    visibility: visible; } }

@keyframes lightActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 100px;
    right: 30px;
    visibility: visible; } }

.light-icon {
  height: 20px;
  width: 20px; }

.settings-action {
  bottom: 170px;
  right: 30px;
  -webkit-animation-name: settingsActionFadeIn;
          animation-name: settingsActionFadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  visibility: hidden;
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

@-webkit-keyframes settingsActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 170px;
    right: 30px;
    visibility: visible; } }

@keyframes settingsActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 170px;
    right: 30px;
    visibility: visible; } }

.settings-icon {
  height: 20px;
  width: 20px; }

.styles-action {
  bottom: 240px;
  right: 30px;
  -webkit-animation-name: stylesActionFadeIn;
          animation-name: stylesActionFadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  visibility: hidden;
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

@-webkit-keyframes stylesActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 240px;
    right: 30px;
    visibility: visible; } }

@keyframes stylesActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 240px;
    right: 30px;
    visibility: visible; } }

.styles-new-icon {
  height: 20px;
  width: 20px; }

.eye-action {
  bottom: 310px;
  right: 30px;
  -webkit-animation-name: eyeActionFadeIn;
          animation-name: eyeActionFadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  visibility: hidden;
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

@-webkit-keyframes eyeActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 310px;
    right: 30px;
    visibility: visible; } }

@keyframes eyeActionFadeIn {
  0% {
    bottom: 30px;
    right: 30px;
    visibility: hidden; }
  100% {
    bottom: 310px;
    right: 30px;
    visibility: visible; } }

.eye-icon {
  height: 20px;
  width: 20px; }

.copied {
  position: absolute;
  bottom: 75px;
  right: 226px;
  background-color: #ed6b6a;
  color: #ffffff;
  padding: 5px;
  border-radius: 4px; }

.semi-black-bg {
  background-color: rgba(0, 0, 0, 0.5); }

.z10 {
  position: absolute;
  z-index: 10; }

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  cursor: pointer; }

.info-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 15%;
  padding-top: 5%;
  height: calc(100% - 54px); }

.features-container {
  color: white; }
  .features-container h1 {
    color: white; }
  .features-container h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px; }
  .features-container h4 {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px; }
  .features-container ul {
    padding-left: 20px; }
    .features-container ul li {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px; }
  .features-container-floorplan {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 20px; }
    .features-container-floorplan-feature {
      margin-right: 15%;
      color: white; }

.images-container {
  position: relative; }
  .images-container .arrow {
    width: 36px;
    height: 36px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    top: calc(50% - 18px);
    color: white;
    border: 1px solid white;
    border-radius: 36px;
    cursor: pointer; }
    .images-container .arrow-left {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg);
      left: 10px; }
    .images-container .arrow-right {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      right: 10px; }

.minimap-container {
  position: relative; }
  .minimap-container-hotspot {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: rgba(62, 207, 175, 0.5);
    border: 2px solid #3ECFAF;
    background-clip: content-box;
    padding: 8px;
    cursor: pointer; }
    .minimap-container-hotspot-active {
      cursor: auto;
      -webkit-animation: activeHotspot 2s infinite;
              animation: activeHotspot 2s infinite; }

.w-40 {
  width: 40%; }

.w-60 {
  width: 60%; }

.t-0 {
  top: 0; }

.r-0 {
  right: 0; }

.w-540 {
  width: 540px; }

.w-607 {
  width: 607px; }

.m-10 {
  margin: 10px; }

.info-minimized-icons {
  color: white;
  width: 16px; }

.info-minimized-action-icons {
  color: white;
  width: 20px;
  cursor: pointer; }

.minimized-title {
  color: white;
  border-left: 0.5px solid white;
  padding-left: 50px; }

.minimized-features {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 20px;
  height: 54px;
  background-color: black;
  -webkit-align-items: center;
          align-items: center; }
  .minimized-features .features-container-floorplan-feature {
    margin-right: 0; }
  .minimized-features-logo {
    width: calc(100% - 540px);
    height: 100%; }
    .minimized-features-logo img {
      height: 100%; }

@-webkit-keyframes activeHotspot {
  0% {
    padding: 8px; }
  50% {
    padding: 2px; }
  100% {
    padding: 8px; } }

@keyframes activeHotspot {
  0% {
    padding: 8px; }
  50% {
    padding: 2px; }
  100% {
    padding: 8px; } }

.hotspot-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .hotspot-list-item {
    cursor: pointer;
    width: 200px; }
    .hotspot-list-item-active {
      font-weight: 800;
      color: #3ECFAF; }

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.feature-container {
  position: fixed;
  width: 200px;
  height: 40px;
  z-index: 1000;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.feature-container.hide {
  opacity: 0;
}

.feature-container.autoplay {
  bottom: 16px;
  left: 16px;
}

.feature-container.cardboard {
  bottom: calc(3% + 47px);
  left: 16px;
}

.feature-container .autoplay-icon,
.feature-container .cardboard-icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
  z-index: 1000;
}

.feature-container .feature-tooltip {
  opacity: 0;
  border-radius: 20px;
  height: 30px;
  width: 130px;
  z-index: 1000;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
  margin-left: 10px;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.feature-container .feature-tooltip.active {
  -webkit-animation: fade-in 1s ease-in-out forwards;
          animation: fade-in 1s ease-in-out forwards;
}

.feature-container .feature-tooltip.inactive {
  -webkit-animation: fade-out 2s ease-in-out forwards;
          animation: fade-out 2s ease-in-out forwards;
}

.cardboard-tooltip {
  opacity: 0;
  position: fixed;
  z-index: 1000;
  bottom: calc(3% + 27px);
  left: 56px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
  width: 230px;
  height: 90px;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.3);
}

.cardboard-tooltip span {
  padding: 20px;
}

.cardboard-tooltip.active {
  -webkit-animation: fade-in 1s ease-in-out forwards;
          animation: fade-in 1s ease-in-out forwards;
}

.cardboard-tooltip.inactive {
  -webkit-animation: fade-out 5s ease-in-out forwards;
          animation: fade-out 5s ease-in-out forwards;
}

.autoplay-icon-tablet {
  position: fixed;
  width: 30px;
  height: 30px;
  object-fit: contain;
  z-index: 1000;
  bottom: 16px;
  left: 16px;
}
#dropdown-basic-button {
  position: absolute;
  top: 120px;
  right: 10px;
  z-index: 1000;
  background-color: #ed6b6a;
  border-color: #ed6b6a;
}

.btn-stop-tour {
  position: absolute;
  top: 170px;
  right: 10px;
  z-index: 1000;
}

.shopping-icon {
  position: fixed;
  width: 40px;
  height: 40px;
  object-fit: contain;
  z-index: 1007;
  top: 5%;
  right: 15px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  cursor: pointer;
}

.shop-icon-tablet {
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  object-fit: contain;
  z-index: 1000;
  bottom: 3%;
  left: 22px;
  cursor: pointer;
}

.shop-icon-tablet.active {
  top: 40%;
}

@media (orientation: landscape) {
  .shop-icon-tablet.active {
    top: 40%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .shop-icon-tablet.active {
    top: 27%;
  }
}

@media (orientation: portrait) {
  .shop-icon-tablet.active {
    top: 55%;
  }
}

.shop-icon-desktop {
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  object-fit: contain;
  z-index: 1000;
  bottom: 5%;
  left: 22px;
  cursor: pointer;
}

.shop-icon-desktop.active {
  top: 5%;
}

.shopping-car-menu {
  width: 95%;
  background: #fafafa;
  z-index: 1020;
  bottom: 0;
  left: 0;
  margin: 0 auto;
}

.shopping-car-menu .title {
  font-family: 'Roboto';
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #4a4a4a;
  padding-left: 20px;
  padding-top: 30px;
}

.shopping-car-menu .items-container {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  will-change: transform;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.shopping-car-menu .items-container::-webkit-scrollbar {
  display: none;
}

.shopping-car-menu .items-container.active {
  background: rgba(255, 255, 255, 0.3);
  cursor: grabbing;
  cursor: -webkit-grabbing;
  /*transform: scale(1);*/
}

.btn.login-button.btn-primary {
  background-color: #000000;
  border-color: #000000;
  border-radius: 20px;
  width: 70%;
}

/* .modal .modal-body,
.modal .modal-content,
.modal .modal-dialog {
  width: 323px;
  height: 275px;
  border-radius: 20px;
  margin: 5px auto;
  box-shadow: 0 4px 6px 0 rgba(138, 138, 138, 0.5);
  background-color: #fafafa;
}

.modal-dialog-centered {
  min-height: 275px;
}

.modal-dialog-centered:before {
  height: 275px;
} */

.scroll-items {
  width: 160%;
}

.shopping-car-menu .shopping-car-item {
  width: 100%;
  height: 240px;
  position: relative;
  display: inline-block;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(138, 138, 138, 0.39),
    0 1px 2px 0 rgba(138, 138, 138, 0.5);
  background-color: #ffffff;
  margin-bottom: 16px;
}

@media screen and (orientation: landscape) {
  .shopping-car-menu .shopping-car-item {
    width: 49%;
  }

  .shopping-car-menu .items-container {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

.shopping-car-menu .shopping-car-item .item {
  width: 100%;
  height: 100%;
}

.shopping-car-menu .shopping-car-item.desktop {
  display: inline-block;
}

.shopping-car-menu .shopping-car-item.mobile {
  display: inline-block;
}

.shopping-car-menu .shopping-car-item .fav-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1000;
}

.shopping-car-menu .shopping-car-item .image {
  width: 188px;
  height: 121px;
  object-fit: contain;
}

.shopping-car-menu .shopping-car-item .image img {
  width: 100%;
  height: 100%;
}
.shopping-car-menu .shopping-car-item .name {
  width: 100%;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  padding-top: 16px;
  padding-left: 16px;
}

.shopping-car-menu .shopping-car-item .retailer {
  width: 100%;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.14;
  letter-spacing: normal;
  color: #6c6c6c;
  padding-left: 16px;
  padding-bottom: 11px;
  padding-top: 5px;
}

.shopping-car-menu .shopping-car-item .colors {
  width: 100%;
  padding-left: 11px;
  height: 110px;
  overflow: auto;
}

.shopping-car-menu .shopping-car-item .colors .color-circle-container {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 5px 0;
}

.shopping-car-menu .shopping-car-item .colors .color-circle-container.active,
.shopping-car-menu .shopping-car-item .colors .color-circle-container:hover {
  border: solid 2px #000000;
}

.shopping-car-item .footer {
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 70px;
  background-color: #252525;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
}

.shopping-car-item .footer .price {
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #ffffff;
  padding-left: 16px;
}

.shopping-car-item .footer .currency {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #ffffff;
}

@media (max-width: 576px) {
  .shop-icon-desktop {
    display: none;
  }

  .shopping-car-menu .title {
    display: none;
  }

  /* .shopping-car-menu .items-container {
    margin-top: 10%;
  } */

  .shopping-car-menu .shopping-car-item {
    width: 100%;
    height: 240px;
    min-height: 240px;
    position: relative;
    display: inline-block;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(138, 138, 138, 0.39),
      0 1px 2px 0 rgba(138, 138, 138, 0.5);
    background-color: #ffffff;
  }

  .shopping-car-menu .shopping-car-item.desktop {
    display: none;
  }

  /* .shopping-car-menu .shopping-car-item.mobile {
    display: inline-block;
  } */

  .shopping-car-menu .shopping-car-item .image {
    width: 188px;
    height: 121px;
    object-fit: contain;
    margin-left: 16px;
  }
}

@media only screen and (device-width: 812px) and (device-height: 375px) and (-webkit-device-pixel-ratio: 3) {
  .shopping-car-menu .shopping-car-item {
    width: 300px;
    height: 249px;
    min-height: 249px;
    min-width: 300px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(138, 138, 138, 0.5);
    background-color: #ffffff;
    position: relative;
    display: inline-block;
    margin: 2em 1em;
  }

  /* .shopping-car-menu .items-container {
    margin-top: 22%;
  } */

  .shopping-car-menu .title {
    display: none;
  }
  .shopping-car-menu .shopping-car-item.desktop {
    display: none;
  }
  .shopping-car-menu .shopping-car-item.mobile {
    display: inline-block;
  }

  .shopping-car-menu .shopping-car-item .image {
    width: 188px;
    height: 121px;
    object-fit: contain;
  }

  .shopping-car-item .footer .price {
    padding-left: 20px;
  }
}

@media screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .shopping-car-menu .shopping-car-item {
    width: 385px;
    height: 249px;
    min-height: 249px;
    min-width: 385px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 rgba(138, 138, 138, 0.5);
    background-color: #ffffff;
    position: relative;
    display: inline-block;
    margin: 2em 1em;
  }
  .shopping-car-menu .shopping-car-item.desktop {
    display: none;
  }
  .shopping-car-menu .shopping-car-item.mobile {
    display: inline-block;
  }

  .shopping-car-menu .shopping-car-item .image {
    width: 188px;
    height: 121px;
    object-fit: contain;
  }

  .shopping-car-item .footer .price {
    padding-left: 20px;
  }
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.test {
  position: absolute;
  z-index: 9999;
  top: 50px;
  right: 250px;
}

#viewer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: #fafafa;
  opacity: 1;
  z-index: 0;
}

#viewer.hide {
  opacity: 0;
  z-index: -1;
}

#viewer-pano {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: #fafafa;
  opacity: 1;
  z-index: 0;
}

#viewer-pano.hide {
  opacity: 0;
  z-index: -1;
}

.red {
  color: red;
  font-weight: bold;
}
.blue {
  color: blue;
  font-weight: bold;
}

.hotspot {
  margin: 25px;
  width: 20px;
  position: absolute;
}

.react-joyride__beacon {
  outline: none !important;
}

@media screen and (device-width: 568px) and (device-height: 320px) and (-webkit-device-pixel-ratio: 2) {
  div.viewer-container {
    display: block !important;
  }
}

.react-joyride__beacon span:first-child {
  background-color: #ed6b6a !important;
}

.react-joyride__beacon span {
  border: 2px solid #ed6b6a !important;
}

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  background: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: 1.5;
  letter-spacing: normal;
  border-radius: 10%;
  color: rgba(255, 255, 255);
  -webkit-transform: translate3d(-50%, -100%, 0);
          transform: translate3d(-50%, -100%, 0);
  opacity: 0;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
}

.tooltip.is-active {
  opacity: 0.75;
  -webkit-transform: translate3d(-50%, calc(-100% - 80px), 0);
          transform: translate3d(-50%, calc(-100% - 80px), 0);
}

.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  background: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: 1.5;
  letter-spacing: normal;
  box-shadow: 0px 3px 24px 4px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  color: rgba(255, 255, 255);
  -webkit-transform: translate3d(-50%, -100%, 0);
          transform: translate3d(-50%, -100%, 0);
  opacity: 0;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  width: 529px;
  height: 298px;
  background-size: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  justify-items: flex-start;
  z-index: -10;
}

.thumbnail .title-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  justify-items: flex-start;
}

.thumbnail.is-active {
  cursor: pointer;
  opacity: 1;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  z-index: 10000;
}

html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0; }

body {
  font-family: sans-serif;
  background: #fff; }

.hide {
  display: none; }

.VideoPlayer {
  position: relative;
  width: 80%;
  height: 80%;
  display: block; }
  .VideoPlayer.full-width {
    width: 100%;
    height: 100%; }
  .VideoPlayer video {
    object-fit: cover; }
  .VideoPlayer.gallery {
    width: 50%;
    height: 50%; }

.controls {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: absolute;
  bottom: 1%;
  left: 1%;
  width: 98%;
  padding: 4px 4px;
  border-radius: 4px;
  background: rgba(30, 30, 30, 0.75);
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s ease-out; }

.VideoPlayer:hover > .controls {
  visibility: visible;
  opacity: 1; }

/* ==  Play/Pause == */
.play_pause_btn {
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 15px;
  transition: all 0.15s ease-out;
  background: rgba(236, 107, 106, 0.5); }
  .play_pause_btn:hover {
    background: rgba(236, 107, 106, 0.75); }
  .play_pause_btn .svg_play_pause {
    fill: #fff;
    width: 25px;
    height: 25px;
    position: relative; }

.time {
  color: #fff;
  font-size: 12px; }

/* ==  Play/Pause == */
/* ==  Mute/Unmute == */
.mute_unmute_btn {
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 15px;
  transition: all 0.15s ease-out;
  background: rgba(236, 107, 106, 0.5); }
  .mute_unmute_btn:hover {
    background: rgba(236, 107, 106, 0.75); }
  .mute_unmute_btn .svg_mute_unmute {
    width: 20px;
    height: 20px;
    position: relative; }
  .mute_unmute_btn .svg_mute_unmute path,
  .mute_unmute_btn .svg_mute_unmute polygon,
  .mute_unmute_btn .svg_mute_unmute rect {
    fill: #fff; }
  .mute_unmute_btn .svg_mute_unmute circle {
    stroke: #fff;
    stroke-width: 2; }

/* ==  Mute/Unmute == */
/* ==  Range == */
.volume_range {
  width: 10% !important; }

input[type='range'] {
  -webkit-appearance: none;
  width: 70%;
  margin: 6px 0;
  background: none; }

input[type='range']:focus {
  outline: none; }

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  background: #796465;
  border-radius: 1.9px;
  border: 0px solid rgba(1, 1, 1, 0); }

input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 20px;
  width: 10px;
  border-radius: 4px;
  background: #eeeeee;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px; }

input[type='range']:focus::-webkit-slider-runnable-track {
  background: #877071; }

input[type='range']::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  background: #796465;
  border-radius: 1.9px;
  border: 0px solid rgba(1, 1, 1, 0); }

input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 20px;
  width: 10px;
  border-radius: 4px;
  background: #eeeeee;
  cursor: pointer; }

input[type='range']::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent; }

input[type='range']::-ms-fill-lower {
  background: #6b5859;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 3.8px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0); }

input[type='range']::-ms-fill-upper {
  background: #796465;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 3.8px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0); }

input[type='range']::-ms-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 20px;
  width: 10px;
  border-radius: 4px;
  background: #eeeeee;
  cursor: pointer;
  height: 8px; }

input[type='range']:focus::-ms-fill-lower {
  background: #796465; }

input[type='range']:focus::-ms-fill-upper {
  background: #877071; }

/* ==  Range == */

.minified-menu {
  width: 200px;
  height: 50px;
  position: absolute;
  left: -75px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  z-index: 10; }
  .minified-menu-title {
    width: 150px;
    height: 50px;
    background: #000000;
    border-radius: 0px 0px 2px 2px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .minified-menu-title h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      text-align: center;
      color: #ffffff; }
  .minified-menu-logo {
    width: 50px;
    height: 50px;
    background: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .minified-menu-logo img {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      max-width: 100%; }

.menu {
  width: 300px;
  height: 100%;
  position: absolute;
  z-index: 2;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: #000000;
  color: #ffffff; }
  .menu-header {
    width: 100%;
    height: 54px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    background: #ffffff;
    color: #000000;
    padding: 12px; }
    .menu-header img {
      max-height: 100%; }
    .menu-header .close-icon {
      cursor: pointer;
      color: black; }
  .menu-item {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 16px 16px 16px 21px;
    width: 100%;
    cursor: pointer;
    background-color: black;
    margin: 2px 0; }
    .menu-item .dropdown-icon {
      width: 24px;
      height: 24px; }
      .menu-item .dropdown-icon-inverted {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }

.submenu-container {
  max-height: 450px;
  overflow: auto;
  -ms-overflow-style: none;
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .submenu-container::-webkit-scrollbar {
    width: 8px; }
  .submenu-container::-webkit-scrollbar-track {
    background-color: #6c6c6c;
    border-radius: 10px; }
  .submenu-container::-webkit-scrollbar-thumb {
    background: #3ECFAF;
    border-radius: 10px; }
  .submenu-container::-webkit-scrollbar-thumb:hover {
    background: rgba(249, 179, 66, 0.8); }

.show-submenu {
  -webkit-animation-name: topFadeIn;
          animation-name: topFadeIn;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.hide-submenu {
  -webkit-animation-name: topFadeOut;
          animation-name: topFadeOut;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.show-submenu-2 {
  -webkit-animation-name: topFadeIn2;
          animation-name: topFadeIn2;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.hide-submenu-2 {
  -webkit-animation-name: topFadeOut2;
          animation-name: topFadeOut2;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.show-submenu-3 {
  -webkit-animation-name: topFadeIn3;
          animation-name: topFadeIn3;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.hide-submenu-3 {
  -webkit-animation-name: topFadeOut3;
          animation-name: topFadeOut3;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.floorplan {
  width: 100%;
  position: relative;
  height: 150px; }
  .floorplan-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    padding: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer; }
    .floorplan-content-active {
      background-color: rgba(0, 0, 0, 0.5); }
      .floorplan-content-active-indicator {
        height: 100%;
        width: 6px;
        background-color: #3ECFAF;
        position: absolute;
        left: 5px;
        top: 0; }
    .floorplan-content:hover {
      background-color: rgba(0, 0, 0, 0.5); }
    .floorplan-content-name {
      font-weight: 900;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 16px; }
    .floorplan-content-features {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .floorplan-content-features-icon {
        width: 15px;
        height: 15px; }
      .floorplan-content-features-value {
        margin: 0 10px;
        font-size: 10px;
        line-height: 12px; }
  .floorplan-thumbnail {
    max-height: 100%; }

.amenity {
  width: 100%;
  position: relative;
  height: 150px; }
  .amenity-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    padding: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer; }
    .amenity-content-active {
      background-color: rgba(0, 0, 0, 0.5); }
      .amenity-content-active-indicator {
        height: 100%;
        width: 6px;
        background-color: #3ECFAF;
        position: absolute;
        left: 5px;
        top: 0; }
    .amenity-content:hover {
      background-color: rgba(0, 0, 0, 0.5); }
    .amenity-content-name {
      font-weight: 900;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 16px; }
    .amenity-content-icons {
      width: 24px;
      position: absolute;
      top: 10px;
      right: 10px; }
    .amenity-content-icon-type {
      width: 24px;
      height: 24px;
      margin-bottom: 15px; }
  .amenity-thumbnail {
    max-height: 100%; }

.minified-left-fade-out {
  -webkit-animation-name: minifiedLeftFadeOut;
          animation-name: minifiedLeftFadeOut;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.minified-left-fade-in {
  -webkit-animation-name: minifiedLeftFadeIn;
          animation-name: minifiedLeftFadeIn;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.left-fade-in {
  -webkit-animation-name: leftFadeIn;
          animation-name: leftFadeIn;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.left-fade-out {
  -webkit-animation-name: leftFadeOut;
          animation-name: leftFadeOut;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.hidden {
  display: none; }

@-webkit-keyframes minifiedLeftFadeOut {
  0% {
    left: -75px; }
  100% {
    left: -125px; } }

@keyframes minifiedLeftFadeOut {
  0% {
    left: -75px; }
  100% {
    left: -125px; } }

@-webkit-keyframes minifiedLeftFadeIn {
  0% {
    left: -125px; }
  100% {
    left: -75px; } }

@keyframes minifiedLeftFadeIn {
  0% {
    left: -125px; }
  100% {
    left: -75px; } }

@-webkit-keyframes leftFadeIn {
  0% {
    left: -300px; }
  100% {
    left: 0; } }

@keyframes leftFadeIn {
  0% {
    left: -300px; }
  100% {
    left: 0; } }

@-webkit-keyframes leftFadeOut {
  0% {
    left: 0; }
  100% {
    left: -300px; } }

@keyframes leftFadeOut {
  0% {
    left: 0; }
  100% {
    left: -300px; } }

@-webkit-keyframes topFadeIn {
  0% {
    height: 0; }
  100% {
    height: 150px; } }

@keyframes topFadeIn {
  0% {
    height: 0; }
  100% {
    height: 150px; } }

@-webkit-keyframes topFadeOut {
  0% {
    height: 150px; }
  100% {
    height: 0; } }

@keyframes topFadeOut {
  0% {
    height: 150px; }
  100% {
    height: 0; } }

@-webkit-keyframes topFadeIn2 {
  0% {
    height: 0; }
  100% {
    height: 300px; } }

@keyframes topFadeIn2 {
  0% {
    height: 0; }
  100% {
    height: 300px; } }

@-webkit-keyframes topFadeOut2 {
  0% {
    height: 300px; }
  100% {
    height: 0; } }

@keyframes topFadeOut2 {
  0% {
    height: 300px; }
  100% {
    height: 0; } }

@-webkit-keyframes topFadeIn3 {
  0% {
    height: 0; }
  100% {
    height: 600px; } }

@keyframes topFadeIn3 {
  0% {
    height: 0; }
  100% {
    height: 600px; } }

@-webkit-keyframes topFadeOut3 {
  0% {
    height: 600px; }
  100% {
    height: 0; } }

@keyframes topFadeOut3 {
  0% {
    height: 600px; }
  100% {
    height: 0; } }

.athum-logo {
  position: absolute;
  left: 30px;
  bottom: 30px;
  width: 32px;
  height: 32px;
  -webkit-animation-name: logoFadeIn;
          animation-name: logoFadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0; }

@-webkit-keyframes logoFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes logoFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.delay-menu {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
  left: -125px;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.details-menu {
  height: 53px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #C4C4C4; }
  .details-menu-item {
    padding: 16px 36px;
    cursor: pointer;
    border-right: 1px solid #474747; }
    .details-menu-item:last-child {
      border-left: 1px solid #474747;
      border-right: none; }
    .details-menu-item.active {
      border-bottom: 3px solid #3ECFAF; }

.image-full {
  object-fit: cover;
  width: 100%;
  height: 100%; }

.video-full {
  width: 80%;
  height: 80%;
  object-fit: cover; }
  .video-full-container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .video-full-container-with-background {
      background-color: rgba(0, 0, 0, 0.85); }

.gallery-carousel-controls {
  position: absolute;
  top: 20px;
  background: #1d1c1b;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 30px;
  color: #ffffff;
  padding: 10px;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .gallery-carousel-controls .left-arrow {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer; }
  .gallery-carousel-controls .right-arrow {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 20px;
    height: 20px;
    margin-left: 10px;
    cursor: pointer; }

.icon-type {
  width: 12px;
  height: 12px;
  margin: 0 5px; }
  .icon-type-md {
    width: 15px;
    height: 15px;
    margin: 0 5px; }

.ellipsis-icon {
  width: 6px;
  height: 6px;
  margin: 0 5px;
  cursor: pointer; }

.placeholder {
  width: 20px;
  height: 20px; }
  .placeholder-left {
    margin-right: 10px; }
  .placeholder-right {
    margin-left: 10px; }

.twod-container {
  width: 100%;
  height: 100%;
  position: relative;
  background: black; }
  .twod-container img {
    object-fit: contain;
    width: 100%;
    height: 100%; }
  .twod-container .hotspot {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: all 0.2s;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    font-family: sans-serif;
    color: #555;
    z-index: 100;
    background-size: 100%;
    background-color: rgba(0, 0, 0, 0.3); }
    .twod-container .hotspot.image {
      background: url(/static/media/Image_hotspot.7566599a.svg);
      background-size: 100%; }
    .twod-container .hotspot.video {
      background: url(/static/media/Video_hotspot.c241d211.svg);
      background-size: 100%; }
    .twod-container .hotspot.infospots {
      background: url(/static/media/Info_Hotspot.3c70028d.svg);
      background-size: 100%; }
    .twod-container .hotspot.hotspots {
      background: url(/static/media/Hotspot_hotspot.96a848e0.svg);
      background-size: 100%; }

.tooltip-image {
  position: absolute;
  width: 300px;
  height: 200px;
  background-color: #4a4a4a;
  color: white;
  opacity: 0;
  -webkit-transform: translate(-34%, -28%);
          transform: translate(-34%, -28%);
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  padding: 8px; }
  .tooltip-image * {
    pointer-events: none; }
  .tooltip-image.active {
    opacity: 1;
    -webkit-transform: translate(-34%, -28%);
            transform: translate(-34%, -28%);
    z-index: 101;
    cursor: pointer;
    border-radius: 8px; }
    .tooltip-image.active.no-thumbnail {
      opacity: 0.75;
      cursor: auto; }

.body-container {
  width: 60%;
  height: 70%;
  margin: auto;
  outline: none; }
  .body-container img {
    object-fit: cover;
    width: 100%;
    height: 100%; }

@-webkit-keyframes fader {
  50% {
    border-color: rgba(255, 255, 255, 0.2); }
  100% {
    border-color: rgba(255, 255, 255, 0.8); } }

@keyframes fader {
  50% {
    border-color: rgba(255, 255, 255, 0.2); }
  100% {
    border-color: rgba(255, 255, 255, 0.8); } }

.image-gallery-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.85); }
  .image-gallery-container .close-icon-container {
    width: 52px;
    height: 52px;
    background: white;
    cursor: pointer; }
    .image-gallery-container .close-icon-container .close-icons {
      color: black; }

.image-container-gallery {
  width: 60%;
  height: 60%; }
  .image-container-gallery img {
    object-fit: cover;
    width: 100%;
    height: 100%; }

.description-container-gallery {
  width: 60%;
  color: white;
  padding-top: 64px; }
  .description-container-gallery p {
    font-weight: normal;
    font-size: 13px; }
  .description-container-gallery h2 {
    font-weight: bold;
    font-size: 24px; }

.video-gallery-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.85); }
  .video-gallery-container .close-icon-container {
    width: 52px;
    height: 52px;
    background: white;
    cursor: pointer; }
    .video-gallery-container .close-icon-container .close-icons {
      color: black; }

.image-container-gallery {
  width: 60%;
  height: 60%; }
  .image-container-gallery img {
    object-fit: cover;
    width: 100%;
    height: 100%; }

.description-container-gallery {
  width: 60%;
  color: white;
  padding-top: 64px; }
  .description-container-gallery p {
    font-weight: normal;
    font-size: 13px; }
  .description-container-gallery h2 {
    font-weight: bold;
    font-size: 24px; }

.description-container-video-gallery {
  width: 50%;
  color: white;
  padding-top: 64px; }

.schedule-button {
  height: 35px;
  width: 150px !important;
  position: absolute;
  top: 20px;
  right: 16px;
  z-index: 1;
  background: #ffffff;
  border-radius: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer; }
  .schedule-button h6 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin: 0px; }

.schedule-steps-container {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  z-index: 3;
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .schedule-steps-container .header-container {
    height: 54px;
    background: #ffffff;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding-right: 18px; }
    .schedule-steps-container .header-container .close-icons {
      cursor: pointer; }
    .schedule-steps-container .header-container .logo {
      height: 100%; }
      .schedule-steps-container .header-container .logo img {
        height: 100%;
        object-fit: contain; }

.step-one-container {
  display: -webkit-flex;
  display: flex;
  width: 393px;
  height: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: start;
          align-items: start;
  color: #ffffff; }
  .step-one-container > * {
    margin-bottom: 24px; }
  .step-one-container .buttons-container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center; }
    .step-one-container .buttons-container button {
      font-size: 10px;
      border-radius: 20px; }
    @media screen and (max-width: 481px) {
      .step-one-container .buttons-container {
        -webkit-flex-direction: column;
                flex-direction: column; }
        .step-one-container .buttons-container button {
          margin-bottom: 10px; } }
  @media screen and (max-width: 481px) {
    .step-one-container {
      width: 100%;
      padding: 10px; } }

.content-container {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  @media screen and (max-width: 481px) {
    .content-container {
      -webkit-align-items: flex-start;
              align-items: flex-start; } }

.step-two-container {
  width: 400px;
  background: #f3f3f5;
  border-radius: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 52px 25px; }
  @media screen and (max-width: 481px) {
    .step-two-container {
      width: 100%;
      border-radius: 0px;
      padding: 0px; }
      .step-two-container p {
        padding: 0px 25px;
        margin-bottom: 0; } }

.step-two-second-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 18px 0px;
  color: #1d1c1b; }
  .step-two-second-container .header {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start; }
    .step-two-second-container .header .header-current-date {
      margin-left: 95px; }
    .step-two-second-container .header h4 {
      margin: 0px;
      font-weight: bold;
      font-size: 20px;
      text-align: center; }
    .step-two-second-container .header h6 {
      font-size: 16px;
      font-weight: normal; }
  .step-two-second-container .list-container {
    overflow: auto;
    width: 100%;
    height: 270px;
    margin-top: 10px;
    padding: 10px; }
    .step-two-second-container .list-container .item {
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 40px;
      width: 100%;
      margin-bottom: 15px;
      padding: 12px 0px;
      text-align: center;
      cursor: pointer; }

.step-three-second-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #1d1c1b; }
  .step-three-second-container .header {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: flex-start;
            align-items: flex-start; }
    .step-three-second-container .header .header-current-date {
      margin-left: 35px; }
    .step-three-second-container .header h4 {
      margin: 0px;
      font-weight: bold;
      font-size: 20px;
      text-align: center; }
    .step-three-second-container .header h6 {
      font-size: 16px;
      font-weight: normal; }
  .step-three-second-container .form-container {
    width: 100%;
    margin-top: 10px; }
    .step-three-second-container .form-container .first-line {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
    .step-three-second-container .form-container .button-container {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      margin-top: 31px; }
      .step-three-second-container .form-container .button-container .button {
        background: #000000;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 20px; }
      @media screen and (max-width: 481px) {
        .step-three-second-container .form-container .button-container {
          margin-top: 5px; } }
    .step-three-second-container .form-container > * {
      margin-bottom: 15px; }
    @media screen and (max-width: 481px) {
      .step-three-second-container .form-container {
        padding: 0px 5px; } }
  .step-three-second-container .subheader-title {
    width: 100%; }

.step-confirmation-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 16px; }
  .step-confirmation-container h3 {
    font-size: 16px;
    font-weight: bold;
    padding: 0px 30px; }
  .step-confirmation-container p {
    padding: 12px 30px; }
  .step-confirmation-container .confirmation-title {
    padding: 35px 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    font-weight: bold;
    font-size: 30px; }
  .step-confirmation-container .footer {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .step-confirmation-container .footer button {
      background: #000000;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 20px; }

.separator {
  background-color: #cdcdcd;
  width: 100%; }
  .separator.top-margin {
    margin: 18px 0px; }

.MuiOutlinedInput-root {
  border-radius: 10px !important; }

.MuiCalendarPicker-root {
  background: #f3f3f5; }

